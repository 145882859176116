<template>
  <div id="wrap">
    <section class="visual">
    <main-header></main-header>
      <!-- Slider main container -->
      <div class="swiper">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide tp1">
            <div class="vslCntBox innWrap">
              <h2 data-swiper-parallax="-5000">
                이용 환경에 맞는 네트워크 최적화, <br />대역폭 변경이 가능한
                Flexible Line 서비스
              </h2>
              <div class="lineTxt" data-swiper-parallax="-4000">
                <span>Flexline</span>
              </div>
              <div class="btn_wrap" data-swiper-parallax="-3000">
                <a @click="$router.push('/goSvcOrd000')" class="mainVsl-btn">서비스 신청</a>
                <a @click="$router.push('/goSvcPod100')" class="mainVsl-btn more">서비스 소개</a>
                <a @click="$router.push('/goSupCns000')" class="mainVsl-btn contact">상담 신청</a>
              </div>
            </div>
          </div>
          <div class="swiper-slide tp2">
            <div class="vslCntBox innWrap">
              <h2 data-swiper-parallax="-5000">
                GiGA 네트워크는 물론<br />상면제공, 운용대행까지 ICT Total Care
                서비스
              </h2>
              <div class="lineTxt" data-swiper-parallax="-4000">
                <span>GiGA office</span>
              </div>
              <div class="btn_wrap" data-swiper-parallax="-3000">
                <a @click="$router.push('/goSvcOrd000')" class="mainVsl-btn">서비스 신청</a>
                <a @click="$router.push('/goSvcPod200')" class="mainVsl-btn more" >서비스 소개</a>
                <a @click="$router.push('/goSupCns000')" class="mainVsl-btn contact">상담 신청</a>
              </div>
            </div>
          </div>
          <div class="swiper-slide tp3">
            <div class="vslCntBox innWrap">
              <h2 data-swiper-parallax="-5000">
                국내 최대 용량의 기업 전용망 보유<br />고품질 인터넷 전용회선
                서비스
              </h2>
              <div class="lineTxt" data-swiper-parallax="-4000">
                <span>Kornet</span>
              </div>
              <div class="btn_wrap" data-swiper-parallax="-3000">
                <a @click="$router.push('/goSvcPod300')" class="mainVsl-btn more">서비스 소개</a>
                <a @click="$router.push('/goSupCns000')" class="mainVsl-btn contact">상담 신청</a>
              </div>
            </div>
          </div>
          <div class="swiper-slide tp4">
            <div class="vslCntBox innWrap">
              <h2 data-swiper-parallax="-5000">
                국내 최다 레퍼런스<br />인정성과 보안성 제공하는 고품질 가상
                사설망
              </h2>
              <div class="lineTxt" data-swiper-parallax="-4000">
                <span>VPN</span>
              </div>
              <div class="btn_wrap" data-swiper-parallax="-3000">
                <a @click="$router.push('/goSvcPod400')" class="mainVsl-btn more">서비스 소개</a>
                <a @click="$router.push('/goSupCns000')" class="mainVsl-btn contact">상담 신청</a>
              </div>
            </div>
          </div>
        </div>
        <!-- If we need pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </section>

    <section class="banner bgGray">
      <div class="innWrap">
        <div class="coSvc">
          <a @click="$router.push('/goSvcPod000')">
            <h2>기업인터넷서비스</h2>
            <p>
              기업인터넷 분야의<br />국내 1위 사업자로써<br />자신있게
              제공드리는<br />고품질 인터넷 서비스를<br />경험하세요
            </p>
          </a>
        </div>
        <div>
          <a @click="$router.push('/goSvcPod100')">
            <h2>Flexline</h2>
            <p>
              이용 환경에 맞는<br />네트워크 최적화,<br />대역폭 변경이
              가능한<br />Flexible Line 서비스
            </p>
          </a>
        </div>
        <div>
          <a @click="$router.push('/goSvcPod200')">
            <h2>GiGAoffice</h2>
            <p>
              10배 빠른 GiGA 네트워크<br />손쉬운 통신 자원관리<br />부대비용
              절감
            </p>
          </a>
        </div>
        <div>
          <a @click="$router.push('/goSvcPod300')">
            <h2>Kornet</h2>
            <p>
              국내 최대 용량<br />기업 전용망으로<br />고품질 인터넷 전용회선<br />서비스
            </p>
          </a>
        </div>
        <div>
          <a @click="$router.push('/goSvcPod400')">
            <h2>VPN</h2>
            <p>
              국내 최다 레퍼런스를<br />가지고 안정성과 보안성을<br />보장하는
              고품질 가상<br />사설망 서비스
            </p>
          </a>
        </div>
      </div>
    </section>
    <section class="mov">
      <h2>동영상으로 보는 Flexline</h2>
      <div class="innWrap">
          <ul>
              <li>
                  <div class="mov-thumb">
                      <a @click="mov1=true" class="pop-open">
                          <span class="btn-play"></span>
                          <img src="@/assets/images/video_thumb01.png" alt="동영상이미지" >
                      </a>
                  </div>
                  <div class="mov-txt">Flexline</div>
              </li>
              <li>
                  <div class="mov-thumb">
                      <a @click="mov2=true" class="pop-open">
                          <span class="btn-play"></span>
                          <img src="@/assets/images/video_thumb02.png" alt="동영상이미지" style="height:320px">
                      </a>
                  </div>
                  <div class="mov-txt">Flexline 웨비나</div>
              </li>
              <li>
                  <div class="mov-thumb">
                      <a @click="mov3=true" class="pop-open">
                          <span class="btn-play"></span>
                          <img src="@/assets/images/video_thumb03.png" alt="동영상이미지" >
                      </a>
                  </div>
                  <div class="mov-txt">KT Enterprise</div>
              </li>
          </ul>
      </div>
    </section>
    <!-- TODO 일시적으로 비활성화 -->
    <!-- <section class="prodSch">
      <div class="innWrap">
        <div class="bg">
          <div class="cont">
            <h3>고객님께 필요한 맞춤상품 ! 지금 바로 확인하세요.</h3>
            <a @click="$router.push('/goSupPrd000')" class="btn-lg btn-purple">내게 맞는 상품 찾기</a>
          </div>
        </div>
      </div>
    </section> -->
    <div class="familySite">
      <div class="innWrap">
        <h3>Family Site</h3>
        <div class="list">
          <div>
            <a
              href="https://cloud.kt.com"
              title="새창열림"
              target="_blank"
              class="icon01"
              ><span>클라우드</span></a
            >
          </div>
          <div>
            <a
              href="http://www.kt-idc.com"
              title="새창열림"
              target="_blank"
              class="icon02"
              >IDC</a
            >
          </div>
          <div>
            <a
              href="https://bizcomm.kt.com/"
              title="새창열림"
              target="_blank"
              class="icon03"
              >기업모바일</a
            >
          </div>
          <div>
            <a
              href="http://gigaoffice.kt.com/"
              title="새창열림"
              target="_blank"
              class="icon04"
              >기가오피스</a
            >
          </div>
          <div>
            <a
              href="https://www.ktvpn.com/"
              title="새창열림"
              target="_blank"
              class="icon05"
              >VPN</a
            >
          </div>
          <div>
            <a
              href="https://www.xroshot.com"
              title="새창열림"
              target="_blank"
              class="icon06"
              ><span>기업메시지(크로샷)</span></a
            >
          </div>
          <div>
            <a
              href="https://say.olleh.com/"
              title="새창열림"
              target="_blank"
              class="icon07"
              >기업메시지 SOHO</a
            >
          </div>
          <div>
            <a
              href="https://www.bizmeka.com/"
              title="새창열림"
              target="_blank"
              class="icon08"
              >Bizmeka EZ</a
            >
          </div>
          <div>
            <a
              href="https://megameet.bizmeka.com/"
              title="새창열림"
              target="_blank"
              class="icon09"
              >화상회의</a
            >
          </div>
          <div>
            <a
              href="https://gw.bizmeka.com/"
              title="새창열림"
              target="_blank"
              class="icon10"
              >그룹웨어</a
            >
          </div>
          <div>
            <a
              href="https://call.bizmeka.com/"
              title="새창열림"
              target="_blank"
              class="icon11"
              >콜매니저</a
            >
          </div>
          <div>
            <a
              href="https://bips.bizmeka.com/main.html"
              title="새창열림"
              target="_blank"
              class="icon12"
              >사회보험</a
            >
          </div>
          <div>
            <a
              href="https://www.mediframe.co.kr/"
              title="새창열림"
              target="_blank"
              class="icon13"
              >메디프레임</a
            >
          </div>
          <div>
            <a
              href="https://securegate.olleh.com"
              title="새창열림"
              target="_blank"
              class="icon14"
              >시큐어게이트</a
            >
          </div>
          <div>
            <a
              href="https://gigaenergy.kt.com/"
              title="새창열림"
              target="_blank"
              class="icon15"
              >기가에너지</a
            >
          </div>
          <div>
            <a
              href="https://smartfactory.kt.co.kr/fmportal/#/home"
              title="새창열림"
              target="_blank"
              class="icon16"
              >스마트팩토리</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- pop[S] -->
    <div id="pop-mainmov01" class="pop-wrap" style="display: block;" v-show="mov1">
        <div class="pop-inner pop-lg">
            <div class="pop-tit">
                Flexline
                <a @click="mov1=false" class="close pop-close-btn"></a>
            </div>
            <div class="pop-con">
                <iframe width="100%" height="590px" src="https://www.youtube.com/embed/nXM0AaVnng4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div id="pop-mainmov02" class="pop-wrap" style="display: block;" v-show="mov2">
        <div class="pop-inner pop-lg">
            <div class="pop-tit">
                Flexline 웨비나
                <a @click="mov2=false" class="close pop-close-btn"></a>
            </div>
            <div class="pop-con">
                <iframe width="100%" height="590px" src="https://www.youtube.com/embed/3Z3IXRO2VL8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <div id="pop-mainmov03" class="pop-wrap" style="display: block;" v-show="mov3">
        <div class="pop-inner pop-lg">
            <div class="pop-tit">
                KT Enterprise
                <a @click="mov3=false" class="close pop-close-btn"></a>
            </div>
            <div class="pop-con">
                <iframe width="100%" height="590px" src="https://www.youtube.com/embed/-6GC04-NM6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    <section>
      <main-footer></main-footer>
    </section>
  </div>
</template>
<script type="text/javascript"></script>
<script>
import "@/assets/css/jquery.bxslider.css";
import "@/assets/css/swiper-bundle.css";
// var slider = require('@/assets/js/jquery.bxslider.js')
var swiper = require("@/assets/js/swiper-bundle.min.js");
var bxslider = require("@/assets/js/jquery.bxslider.js");
import HeaderLayout from "./common/Header.vue";
import FooterLayout from "./common/Footer.vue";
import date from "date-and-time";
import { gigaofficeService } from 'Api'

export default {
  name: "goMain000",
  components: {
    "main-header": HeaderLayout,
    "main-footer": FooterLayout
  },
  data() {
    return {
      mov1: false,
      mov2: false,
      mov3: false,
      isPopOn: false,
    };
  },
  created: function() {},
  mounted: async function() {
    $(document).ready(function() {
      // main swiper
      swiper = new Swiper(".swiper", {
        centeredSlides: true,
        speed: 600,
        parallax: true,
        // autoplay: {
        //   delay: 3500,
        //   disableOnInteraction: false
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      });
      if ($(".familySite > div > .list").length > 0) {
        bxslider.slider = $(".familySite > div > .list").bxSlider({
          auto: false,
          speed: 500, // 이동 속도를 설정
          autoControls: false,
          moveSlides: 1, //한번움직일때 한장씩
          // minSlides:1,  //반응형일때 대응한다.
          maxSlides: 8, //pc크기
          slideWidth: 189,
          slideMargin: 0,
          touchEnabled: false,
          pager: false
        });
      }
    });
  },
  destroyed: function() {},
  methods: {
  }
};
</script>
